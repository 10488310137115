<template>
  <div>
    <b-card>
      <validation-observer
        ref='form'
        v-slot='{invalid}'
      >
        <b-card v-if='!isDataLoaded'>
          <div class='text-center text-success my-2'>
            <b-spinner class='align-middle' />
          </div>
        </b-card>
        <b-form
          v-else
          novalidate
          @submit.prevent='onSubmit'
        >
          <b-row class='align-items-end'>
            <b-col
              cols='12'
            >
              <LocaleTabs
                :errors='formErrors'
                name='title'
              >
                <template
                  v-for='(i,k,idx) in languages'
                  :slot='`lang${idx}`'
                  slot-scope='slotScope'
                >
                  <ValidationProvider
                    :key='idx'
                    v-slot='{ errors }'
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid='`title.${k}`'
                    :name="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    rules='required'
                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-input
                        v-model='form.title[k]'
                        :placeholder="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>

            <b-col
              cols='12'
              xl='6'
              md='6'
            >
              <validation-provider
                v-slot='{ errors }'
                vid='type'
                :name="$t('labels.type')"
                rules='required'
              >
                <b-form-group
                  :label="$t('labels.type')"
                >
                  <v-select
                    v-model='form.model'
                    :dir='$store.state.appConfig.layout.direction'
                    label='name'
                    :options='statuses'
                    :reduce='item => item.id'
                    :placeholder="$t('labels.type')"
                  >
                    <template #no-options>{{ $t('No options') }}</template>
                  </v-select>
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md='6'
              cols='12'
            >
              <validation-provider
                v-slot='{ errors }'
                :name="$t('sort')"
                vid='sort'
                :rules='{
                         numeric: true,
                       }'
              >
                <b-form-group>
                  <template #label>
                    <span>{{ $t('sort') }}</span>
                  </template>
                  <b-form-input
                    v-model='form.sort'
                    :placeholder="$t('sort')"
                    autocomplete='off'
                    type='number'
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>


            <!-- checkbox -->
            <b-col cols='12'>
              <b-card-text class='mb-0'>
                {{ $t('labels.status') }}
              </b-card-text>
              <b-form-checkbox
                v-model='form.status'
                checked='true'
                value='1'
                unchecked-value='0'
                name='check-button'
                switch
              />
            </b-col>

            <!-- submit and reset -->
            <b-col cols='12 text-right'>
              <LoadingButton />
              <b-button
                type='reset'
                variant='outline-secondary'
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'
import { websiteMainUrl } from '@/services/url.service'

export default {
  mixins: [formMixin],
  data() {
    return {
      // types: [],
      form: {
        title: {
          en: null,
          ar: null,
        },
        // image: null,
        status: 1,
        model: null,
        sort: null,
      },

      isDataLoaded: false,
      statuses: [],
    }
  },

  created() {
    this.getConstants()
    if (this.isEdit) {
      this.loadData()
    } else {
      this.isDataLoaded = true
    }
  },
  methods: {
    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            title: data.title,
            status: data.status,
            model: data.model,
            sort: data.sort,
          }
          this.isDataLoaded = true
        })
    },
    getConstants() {
      this.axios.get(`${websiteMainUrl()}/model-options/type-parent-category`)
        .then(res => {
          this.statuses = res.data.data
        })
    },
  },

}
</script>
